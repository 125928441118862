import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/styles/global.scss"; //全局css样式

import "./permission/permission";

const app = createApp(App);

app.use(store).use(router).mount("#app");
