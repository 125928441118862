
import { User } from "@/services/bo/user-bo";
import { UserInfoCache } from "@/utils/auth";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

interface _DModel {
  userInfo: User | null;
}
export default defineComponent({
  name: "NavBar",
  setup() {
    const store = useStore();
    const router = useRouter();
    const userUrl = router.resolve({
      path: "/my-site/user",
    });
    return {
      store,
      userUrl,
    };
  },
  data(): _DModel {
    return {
      userInfo: null,
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    showLogin() {
      this.store.commit("wechat/showWxDialog");
    },
    getUserData() {
      this.userInfo = UserInfoCache.getUserInfo();
    },
    goUserCenter() {
      // this.$router.push("/my-site/user");
      window.open(this.userUrl.href);
    },
  },
});
