import { HttpClient } from "../request";

class NavStateCountApi {
  static async navStateCount(): Promise<string> {
    return HttpClient.request<string>({
      url: "/stat/app/visit/do",
      method: "post",
    });
  }
}

export { NavStateCountApi };
