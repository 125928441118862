
import { defineComponent } from "vue";
import {
  BorderType,
  SearchDataType,
  SearchFormType,
  searchName,
} from "../layout-data";

interface _DModel {
  searchForm: SearchFormType;
  boxBorder: BorderType;
}

export default defineComponent({
  name: "AppMain",
  data(): _DModel {
    return {
      searchForm: {
        sName: searchName,
        currentIndex: 1,
        placeWord: "百度一下",
        keyWord: null,
      },
      boxBorder: {
        isShowBorder: true,
        focusColor: "#2932E1",
      },
    };
  },
  mounted() {
    (this.$refs.getFocus as HTMLInputElement).focus();
  },
  methods: {
    switchSearch(data: SearchDataType) {
      this.searchForm.currentIndex = data.id;
      this.searchForm.placeWord = data.placeholder;
      this.boxBorder.focusColor = data.color;
      (this.$refs.getFocus as HTMLInputElement).focus();
    },
    searchData() {
      const word = (this.searchForm.keyWord || "").trim();
      if (this.searchForm.currentIndex == 1) {
        window.open("https://www.baidu.com/s?wd=" + word);
      } else if (this.searchForm.currentIndex == 2) {
        window.open("https://cn.bing.com/search?q=" + word);
      } else if (this.searchForm.currentIndex == 3) {
        window.open("https://www.sogou.com/web?query=" + word);
      } else if (this.searchForm.currentIndex == 4) {
        window.open("https://www.so.com/s?q=" + word);
      }
    },
  },
});
