import { Module, MutationTree } from "vuex";
import { TopState } from "./top";

interface LoginState {
  wxFlag: boolean;
}

const state: LoginState = {
  wxFlag: false,
};

const mutations: MutationTree<LoginState> = {
  showWxDialog(state) {
    state.wxFlag = true;
  },
  closeWxDialog(state) {
    state.wxFlag = false;
  },
};

const moduleWxLogin: Module<LoginState, TopState> = {
  namespaced: true,
  state,
  mutations,
};

export { moduleWxLogin };
