import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a803724"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aside-menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "first-icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "first-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.path
      }, [
        (item.props)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.children, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                key: option.path
              }, [
                (option.meta)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["item-first fx-center", _ctx.initPath == option.path ? 'item-back' : '']),
                      onClick: _withModifiers(($event: any) => (_ctx.switchTitle(option)), ["stop"])
                    }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("img", {
                          class: "icon-style",
                          src: 
                  require('@/assets/icon/channel/' + option.meta.icon + '.svg')
                
                        }, null, 8, _hoisted_4)
                      ]),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(option.meta.title), 1)
                    ], 10, _hoisted_2))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}