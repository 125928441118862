import { createVNode, render } from "vue";
// 引入信息提示组件
import MessageMain from "@/components/message-main.vue";

const div = document.createElement("div");
div.setAttribute("class", "message-container");
document.body.appendChild(div);

let timer: number | undefined;

interface MsgType {
  type: string;
  text: string;
}

export default (msg: MsgType) => {
  const { type, text } = msg;
  const vNode = createVNode(MessageMain, { type, text });
  render(vNode, div);
  clearTimeout(timer);
  timer = setTimeout(() => {
    render(null, div);
  }, 1500);
};
