import { createStore, Store } from "vuex";
import { modulePermission, PermissionStateType } from "./modules/permission";
import { TopState } from "./modules/top";
import { moduleUser, UserState } from "./modules/user";
import { moduleWxLogin } from "./modules/wx-login";

const topState: TopState = {
  nonSense: "",
};

interface ModuleMergedState extends TopState {
  user: UserState;
  permission: PermissionStateType;
}

const map2mergedState = function (topState: TopState) {
  return topState as unknown as ModuleMergedState;
};

const topStore: Store<TopState> = createStore({
  state: topState,
  getters: {
    token: (state: TopState) => map2mergedState(state).user.token,
    avatar: (state) => map2mergedState(state).user.avatar,
    name: (state) => map2mergedState(state).user.name,
    userId: (state) => map2mergedState(state).user.user?.uid,
    userData: (state) => map2mergedState(state).user.user,
    permission_routes: (state) => map2mergedState(state).permission.routes,
  },
  mutations: {},
  actions: {},
  modules: {
    user: moduleUser,
    permission: modulePermission,
    wechat: moduleWxLogin,
  },
});

export default topStore;
