import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79fb01da"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "box-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "msg" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "message-box",
        style: _normalizeStyle(_ctx.getMsgStyle)
      }, [
        _createElementVNode("img", {
          class: "box-icon",
          src: _ctx.getMsgIcon
        }, null, 8, _hoisted_1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
      ], 4), [
        [_vShow, _ctx.visible]
      ])
    ]),
    _: 1
  }))
}