import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "../layout/index.vue";

export const constantRoutes: Array<RouteRecordRaw> = [
  // {
  //   path: "/nav/register",
  //   name: "NavRegister",
  //   component: () => import("@/views/register/index.vue"),
  //   meta: {
  //     title: "注册",
  //   },
  // },
  {
    path: "/login/wechat",
    name: "WxLoginWait",
    component: () => import("@/views/login/wechat.vue"),
    meta: {
      title: "微信登录",
    },
  },
  // {
  //   path: "/login/test",
  //   name: "TestLogin",
  //   component: () => import("@/views/login/test.vue"),
  //   meta: {
  //     title: "测试登录",
  //   },
  // },
  //导航工具
  {
    path: "/tools/timestamp",
    name: "TimeStamp",
    component: () => import("@/views/tools/time-stamp.vue"),
    meta: {
      title: "时间戳转换",
    },
  },
  //公共频道
  {
    path: "/",
    name: "NavHome",
    component: Layout,
    redirect: "/community",
    meta: {
      title: "首页",
      icon: "Setting",
    },
    props: true,
    children: [
      {
        path: "/community",
        name: "Community",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "1",
          title: "社区",
          icon: "com-white",
          key: "channel-sites-community",
        },
      },
      {
        path: "/news",
        name: "News",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "2",
          title: "新闻",
          icon: "news-white",
          key: "channel-sites-news",
        },
      },
      {
        path: "/shopping",
        name: "Shopping",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "3",
          title: "购物",
          icon: "shop-white",
          key: "channel-sites-shopping",
        },
      },
      {
        path: "/liveTV",
        name: "LiveTV",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "4",
          title: "影视直播",
          icon: "live-white",
          key: "channel-sites-live",
        },
      },
      {
        path: "/searcher",
        name: "Searcher",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "5",
          title: "搜索",
          icon: "search-white",
          key: "channel-sites-searcher",
        },
      },
      {
        path: "/music",
        name: "Music",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "6",
          title: "音乐",
          icon: "music-white",
          key: "channel-sites-music",
        },
      },
      {
        path: "/tools",
        name: "Tools",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "7",
          title: "工具",
          icon: "tools-white",
          key: "channel-sites-tools",
        },
      },
      {
        path: "/translate",
        name: "Translate",
        component: () => import("@/views/channel/index.vue"),
        meta: {
          id: "8",
          title: "翻译",
          icon: "translate-white",
          key: "channel-sites-translate",
        },
      },
    ],
  },
];
//异步路由
export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: "/my",
    name: "MySite",
    component: Layout,
    redirect: "/my-site",
    props: true,
    children: [
      {
        path: "/my-site",
        name: "MySite",
        component: () => import("@/views/my-site/index.vue"),
        meta: {
          title: "我的收藏",
          icon: "my-white",
          key: "my-site",
        },
      },
    ],
  },
  {
    path: "/my-site/user",
    name: "UserCenter",
    component: () => import("@/views/my-site/user-center.vue"),
    meta: {
      title: "用户中心",
    },
  },
];

const makeRouter = () => {
  return createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoutes,
  });
};

const router = makeRouter();

export function resetRouter() {
  const newRouter = makeRouter();
  router.getRoutes().forEach((one) => {
    if (one.name) {
      router.removeRoute(one.name);
    }
  });
  newRouter.getRoutes().forEach((one) => {
    router.addRoute(one);
  });
}

export default router;
