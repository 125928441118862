interface AsideDataType {
  menuId: string;
  userId: string;
  menuName: string;
  path: string;
  icon?: string;
  children?: AsideDataType[];
  key: string;
}

interface SearchDataType {
  id: number;
  name: string;
  placeholder: string;
  color: string;
}

interface BorderType {
  isShowBorder: boolean;
  focusColor: string;
}

interface SearchFormType {
  sName: SearchDataType[];
  currentIndex: number;
  placeWord: string;
  keyWord: string | null;
}

const searchName: SearchDataType[] = [
  {
    id: 1,
    name: "百度",
    placeholder: "百度一下",
    color: "#2932E1",
  },
  {
    id: 2,
    name: "Bing",
    placeholder: "微软Bing搜索",
    color: "#007daa",
  },
  {
    id: 3,
    name: "搜狗",
    placeholder: "搜狗搜索",
    color: "#FE650F",
  },
  {
    id: 4,
    name: "360搜索",
    placeholder: "360搜索",
    color: "#0FB264",
  },
];

export {
  AsideDataType,
  SearchDataType,
  SearchFormType,
  BorderType,
  searchName,
};
