import { LoginToken, User } from "@/services/bo/user-bo";

const LoginTokenKey = "nav-site-login-token";

export function getToken() {
  const cache = window.localStorage.getItem(LoginTokenKey);
  if (cache) {
    const cacheToken = JSON.parse(cache);
    if (cacheToken.token && cacheToken.exp > Date.now() / 1000) {
      return cacheToken.token;
    }
  }
  return null;
}

export function setToken(loginToken: LoginToken) {
  if (loginToken) {
    window.localStorage.setItem(LoginTokenKey, JSON.stringify(loginToken));
  } else {
    window.localStorage.removeItem(LoginTokenKey);
  }
}

export function removeToken() {
  window.localStorage.removeItem(LoginTokenKey);
}

export class UserInfoCache {
  static NAV_USER = "nav_user_info";

  // 存储用户信息 User
  static getUserInfo() {
    const cacheData = window.localStorage.getItem(UserInfoCache.NAV_USER);
    if (cacheData) {
      const parsed = JSON.parse(cacheData);
      if (UserInfoCache._validate(parsed)) {
        return parsed;
      }
    }
    return undefined;
  }

  static cacheItem(userInfo: User) {
    if (UserInfoCache._validate(userInfo)) {
      window.localStorage.setItem(
        UserInfoCache.NAV_USER,
        JSON.stringify(userInfo)
      );
      return true;
    }
    return false;
  }

  static onLogout() {
    window.localStorage.removeItem(UserInfoCache.NAV_USER);
  }

  static _validate(data: User) {
    return data.uid && data.name;
  }
}
