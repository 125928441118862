
import { defineComponent } from "vue";
import { AppConfig } from "@/config/app-config";
import appInfo from "@/config/app-data.js";
import { useStore } from "vuex";
import newHref from "@/utils/wx-login-style";
import MessageMainVue from "@/utils/message";
import { PhoneCodeType, PhoneFormType } from "@/services/bo/user-login";

interface _DModel {
  loginFlag: boolean;
  timer: number | undefined;
  codeState: PhoneCodeType;
  phoneForm: PhoneFormType;
}

export default defineComponent({
  name: "NavLogin",
  setup() {
    const store = useStore();
    return { store };
  },
  data(): _DModel {
    return {
      loginFlag: true,
      timer: 0,
      codeState: {
        btnDisabled: false,
        waitTime: 60,
        codeBtnWord: "获取验证码",
      },
      phoneForm: {
        phoneNum: null,
        phoneCode: null,
      },
    };
  },
  mounted() {
    this.setWxLogin();
  },
  methods: {
    setWxLogin() {
      this.loginFlag = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getWxLogin();
      }, 100);
    },
    //切换微信登录
    getWxLogin() {
      let state = parseInt(new Date().getTime() / 1000 + "");
      let obj = new WxLogin({
        self_redirect: true,
        id: "login_container",
        appid: appInfo.wxId,
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent(AppConfig.urlDeploy + "/login/wechat"),
        // redirect_uri: encodeURIComponent("http://www.singleee.com/community"),
        state: state,
        style: "black",
        href: newHref,
      });
      console.log(obj);
    },
    //切换手机号登录
    async setPhoneLogin() {
      this.loginFlag = false;
    },
    async getVCode() {
      if (!this.phoneForm.phoneNum) {
        MessageMainVue({ type: "error", text: "您输入的手机号为空！" });
        return;
      }
      try {
        //请求
        this.codeState.waitTime--;
        this.codeState.btnDisabled = true;
        this.codeState.codeBtnWord = `${this.codeState.waitTime}s 后重新发送`;
        let timer = setInterval(() => {
          if (this.codeState.waitTime > 1) {
            this.codeState.waitTime--;
            this.codeState.codeBtnWord = `${this.codeState.waitTime}s 后重新发送`;
          } else {
            clearInterval(timer);
            this.codeState.codeBtnWord = "获取验证码";
            this.codeState.btnDisabled = false;
            this.codeState.waitTime = 10;
          }
        }, 1000);
      } catch (e) {
        console.log(e);
      }
    },
    phoneLogin() {
      console.log("手机登录");
    },
    closeDialog() {
      this.store.commit("wechat/closeWxDialog");
    },
  },
});
