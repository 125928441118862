import { User } from "@/services/bo/user-bo";
import getPageTitle from "@/utils/get-page-title";
import { RouteRecordRaw } from "vue-router";
import router from "../router";
import store from "../store";

const blackList = ["/my-site", "/my-site/user"];

router.beforeEach(async (to, from, next) => {
  // 动态展示标题
  document.title = getPageTitle(to.meta.title as string);
  const hasToken = store.getters.token;
  //判断是否登录
  if (hasToken) {
    const userData: User | undefined = store.getters.userData;
    if (userData) {
      next();
    } else {
      try {
        await store.dispatch("user/getInfo");
        const accessRoutes = (await store.dispatch(
          "permission/generateRoutes",
          true
        )) as Array<RouteRecordRaw>;
        accessRoutes.forEach((one) => {
          router.addRoute(one);
        });
        next({ ...to, replace: true });
      } catch (e) {
        await store.dispatch("user/resetToken");
        const { message } = e as { message: string };
        console.log(message);
      }
    }
  } else {
    //token过期后清除登录信息
    await store.dispatch("user/logout");
    if (blackList.indexOf(to.path) !== -1) {
      next("/");
    } else {
      const accessRoutes = (await store.dispatch(
        "permission/generateRoutes",
        false
      )) as Array<RouteRecordRaw>;
      accessRoutes.forEach((one) => {
        router.addRoute(one);
      });
      next();
    }
  }
});
