
import { defineComponent, PropType } from "vue";
import { RouteRecordRaw } from "vue-router";

interface _DModel {
  initPath: string;
}

export default defineComponent({
  name: "AsideMenu",
  props: {
    itemList: {
      type: Array as PropType<RouteRecordRaw[]>,
      required: true,
    },
  },
  data(): _DModel {
    return {
      initPath: "/my-site",
    };
  },
  watch: {
    $route(to) {
      this.initPath = to.path;
    },
  },
  mounted() {
    // console.log(this.itemList);
    this.initPath = this.$route.path;
  },
  methods: {
    switchTitle(item: RouteRecordRaw) {
      if (item.meta) {
        const name = {
          cName: item.meta.title,
          sName: item.meta.key,
        };
        window.sessionStorage.setItem("nav-channel-name", JSON.stringify(name));
        this.$router.push(item.path);
      }
    },
  },
});
