import { AppConfig } from "@/config/app-config";
import topStore from "@/store";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { LoginInvalidException } from "./exceptions/LoginInvalidException";

class HttpCode {
  static SUCCESS = 0;
  static E_COMMON = 1;
  static E_TOKEN_INVALID = 1000;
  static E_LOGIN_INVALID = 1001;
}

interface HttpResult<T> {
  code: number;
  msg?: string;
  data: T;
}

const TokenHeaderKey = "x-auth-token";
const AppIdKey = "appid";

//请求数据结果判断
const HttpResultUtil = {
  parseResult<T>(resultData: string | HttpResult<T>): T {
    let parsed: HttpResult<T>;
    if (typeof resultData === "string") {
      parsed = JSON.parse(resultData) as HttpResult<T>;
    } else {
      parsed = resultData as HttpResult<T>;
    }

    if (parsed.code === HttpCode.SUCCESS) {
      return parsed.data;
    } else if (parsed.code === HttpCode.E_LOGIN_INVALID) {
      console.log("login invalid : " + parsed.msg);
      throw new LoginInvalidException("登录失效");
    }
    throw new Error(parsed.msg);
  },
};

class HttpServer {
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: AppConfig.urlApi, // url = base url + request url
      // withCredentials: true, // send cookies when cross-domain requests
      timeout: 5000, // request timeout
    });
    //请求拦截器
    this.instance.interceptors.request.use(
      this.loginAgentInterceptor,
      (error) => Promise.reject(error)
    );
  }

  private loginAgentInterceptor = (config: AxiosRequestConfig) => {
    if (!config.headers) {
      config.headers = {};
    }
    const existToken = topStore.getters.token;
    if (existToken) {
      config.headers[TokenHeaderKey] = existToken;
    }
    config.headers[AppIdKey] = "200230002";
    return config;
  };

  async request<T>(config: AxiosRequestConfig): Promise<T> {
    const response = await this.instance.request<HttpResult<T>>(config);
    const result = response.data;
    try {
      return HttpResultUtil.parseResult<T>(result);
    } catch (e) {
      if (e instanceof LoginInvalidException) {
        // TODO clear user info and reload page
        window.location.reload();
      } else {
        console.log(e);
      }
      return Promise.reject(e);
    }
  }
}

const httpClient = new HttpServer();

export { httpClient as HttpClient };
