
import { defineComponent } from "vue";
import { RouteRecordRaw } from "vue-router";
import { useStore } from "vuex";
import AsideMenu from "./aside-menu.vue";

export default defineComponent({
  name: "AsideBar",
  components: {
    AsideMenu,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  computed: {
    permission_routes(): Array<RouteRecordRaw> {
      return this.store.getters.permission_routes;
    },
    showRoutes(): Array<RouteRecordRaw> {
      return this.permission_routes.filter((one) => {
        return one.props;
      });
    },
  },
});
