
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "MessageMain",
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "warn",
    },
  },
  setup() {
    const visible = ref(false);
    onMounted(() => {
      visible.value = true;
      setTimeout(() => {
        visible.value = false;
      }, 1000);
    });
    return { visible };
  },
  computed: {
    getMsgIcon(): string {
      let type = this.type;
      if (type === "success") {
        return require("@/assets/icon/msg/msg-success.svg");
      } else if (type === "error") {
        return require("@/assets/icon/msg/msg-error.svg");
      } else {
        return require("@/assets/icon/msg/msg-warn.svg");
      }
    },
    getMsgStyle() {
      let type = this.type;
      if (type === "success") {
        return { backgroundColor: "#F0F9EB", color: "#67C23A" };
      } else if (type === "error") {
        return { backgroundColor: "#FEF0F0", color: "#F56C6C" };
      } else {
        return { backgroundColor: "#FDF6EC", color: "#E6A23C" };
      }
    },
  },
});
