import { resetRouter } from "@/router";
import { UserDataApi } from "@/services/apis/user-data-api";
import { User, UserRole } from "@/services/bo/user-bo";
import { getToken, removeToken, setToken, UserInfoCache } from "@/utils/auth";
import { ActionTree, Module, MutationTree } from "vuex";
import { TopState } from "./top";

interface UserState {
  token?: string;
  name?: string;
  avatar?: string;
  user?: User;
  roles: Array<UserRole>;
}

const state: UserState = {
  token: getToken(),
  name: "",
  avatar: "",
  roles: [],
};

const mutations: MutationTree<UserState> = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_USER: (state, user) => {
    state.user = user;
  },
};

const actions: ActionTree<UserState, TopState> = {
  //wx login
  async wxLogin({ commit }, code) {
    const loginToken = await UserDataApi.wechatWebLogin(code);
    commit("SET_TOKEN", loginToken.token);
    setToken(loginToken);
  },

  // user login
  async login({ commit }, userInfo) {
    const { username, password } = userInfo;
    const loginToken = await UserDataApi.passwdLogin(
      username.trim(),
      password.trim()
    );
    commit("SET_TOKEN", loginToken.token);
    setToken(loginToken);
  },

  // get user info
  async getInfo({ commit }) {
    let userInfo: User | null = UserInfoCache.getUserInfo();
    if (!userInfo) {
      userInfo = await UserDataApi.getUserData();

      UserInfoCache.cacheItem(userInfo);
    }
    commit("SET_USER", userInfo);
    commit("SET_NAME", userInfo.name);
    commit("SET_AVATAR", userInfo.avatar);
    return userInfo;
  },

  resetToken({ commit }): Promise<void> {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      removeToken();
      resolve();
    });
  },

  logout({ commit }): Promise<void> {
    return new Promise((resolve) => {
      removeToken();
      UserInfoCache.onLogout();
      commit("SET_TOKEN", "");
      resetRouter();
      resolve();
    });
  },
};

const moduleUser: Module<UserState, TopState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export { moduleUser, UserState };
