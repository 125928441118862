
import { computed, defineComponent } from "vue";
import NavLogin from "@/components/nav-login.vue";
import { useStore } from "vuex";
import { AppConfig } from "./config/app-config";
import { NavStateCountApi } from "./services/apis/nav-count";

export default defineComponent({
  name: "App",
  components: {
    NavLogin,
  },
  setup() {
    const store = useStore();
    const loginFlag = computed(() => store.state.wechat.wxFlag);
    return {
      loginFlag,
      store,
    };
  },
  created() {
    this.countUserState();
  },
  mounted() {
    this.checkWxLogin();
  },
  methods: {
    checkWxLogin() {
      window.addEventListener(
        "message",
        (e) => {
          if (e.origin === AppConfig.urlDeploy && e.data === "登录成功") {
            window.location.reload();
          }
        },
        false
      );
    },
    countUserState() {
      const time = new Date();
      const date = {
        year: time.getFullYear(),
        month: time.getMonth(),
        day: time.getDate(),
      };
      const oldDate = window.localStorage.getItem("nav-state-date");
      if (oldDate) {
        const localDate = JSON.parse(oldDate);
        if (
          date.year == localDate.year &&
          date.month == localDate.month &&
          date.day == localDate.day
        ) {
          return;
        } else {
          //调用接口
          this.visitNavCount();
          window.localStorage.setItem("nav-state-date", JSON.stringify(date));
        }
      } else {
        //调用接口
        this.visitNavCount();
        window.localStorage.setItem("nav-state-date", JSON.stringify(date));
      }
    },
    async visitNavCount() {
      try {
        const result = await NavStateCountApi.navStateCount();
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
  },
});
