
import { defineComponent } from "vue";
import AsideBar from "./components/AsideBar/index.vue";
import NavBar from "./components/NavBar.vue";
import AppMain from "./components/AppMain.vue";
export default defineComponent({
  name: "LayoutNav",
  components: {
    AsideBar,
    NavBar,
    AppMain,
  },
  methods: {
    goBeiAnGov() {
      window.open("https://beian.miit.gov.cn/");
    },
  },
});
