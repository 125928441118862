import md5 from "js-md5";
import { LoginToken, User, UserRole } from "../bo/user-bo";
import { HttpClient } from "../request";

class UserDataApi {
  static passwdRegister(
    loginName: string,
    passwd: string,
    registerCode: string
  ): Promise<string> {
    const data: FormData = new FormData();
    data.append("loginName", loginName);
    data.append("pwd", md5(passwd));
    data.append("registerCode", registerCode);
    return HttpClient.request<string>({
      url: "/user/update/pwd/register",
      method: "post",
      data: data,
    });
  }

  static passwdLogin(loginName: string, passwd: string): Promise<LoginToken> {
    const randomNumber = Math.random() * 0xefffffff + 0x10000000;
    const salt = Math.round(randomNumber).toString(16);
    const data = {
      name: loginName,
      key: salt,
      signData: md5(loginName + salt + md5(passwd)),
    };
    return HttpClient.request<LoginToken>({
      url: "/user/auth/pwd/login",
      method: "post",
      data: data,
    });
  }

  static wechatWebLogin(authCode: string): Promise<LoginToken> {
    const data: FormData = new FormData();
    data.append("code", authCode);
    return HttpClient.request<LoginToken>({
      url: "/user/auth/wechat/web/login",
      method: "post",
      data: data,
    });
  }

  static getUserData(): Promise<User> {
    return HttpClient.request<User>({
      url: "/user/data/get/user",
      method: "get",
    });
  }

  static getUserRole(): Promise<Array<UserRole>> {
    return HttpClient.request<Array<UserRole>>({
      url: "/user/data/get/roles",
      method: "get",
    });
  }
}

export { UserDataApi };
