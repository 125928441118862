import { asyncRoutes, constantRoutes } from "@/router";
import { UserRole } from "@/services/bo/user-bo";
import { RouteRecordRaw } from "vue-router";
import { ActionTree, Module, MutationTree } from "vuex";
import { TopState } from "./top";

interface PermissionStateType {
  routes: Array<RouteRecordRaw>;
  addRoutes: Array<RouteRecordRaw>;
}

const state: PermissionStateType = {
  routes: [],
  addRoutes: [],
};

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles: Array<UserRole>, route: RouteRecordRaw) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => {
      const { code } = role;
      let routeRoles: Array<string> = [];
      if (route.meta) {
        routeRoles = route.meta["roles"] as Array<string>;
      }
      return routeRoles.includes(code);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(
  routes: Array<RouteRecordRaw>,
  roles: Array<UserRole>
) {
  const res: Array<RouteRecordRaw> = [];

  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

const mutations: MutationTree<PermissionStateType> = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = routes.concat(constantRoutes);
  },
};

const actions: ActionTree<PermissionStateType, TopState> = {
  generateRoutes({ commit }, flag: boolean) {
    let accessedRoutes: RouteRecordRaw[] = [];
    return new Promise((resolve) => {
      if (flag) {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, []);
        commit("SET_ROUTES", accessedRoutes);
      } else {
        commit("SET_ROUTES", []);
      }
      resolve(accessedRoutes);
    });
  },
};

const modulePermission: Module<PermissionStateType, TopState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export { modulePermission, PermissionStateType };
